import {inject} from 'aurelia-dependency-injection';
import { EmployeeService } from '../../shared/services/employeeservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {JwtService} from '../../shared/services/jwtservice';
import {Router} from 'aurelia-router';
import {LoginService} from "../../shared/services/loginservice";


@inject(EmployeeService,ValidationControllerFactory,JwtService,Router,LoginService)
export class ChangePasswordComponent {
  currentPassword = '';
  newPassword = '';
  newPasswordFieldType = 'password';
  confirmPassword = '';
  errors = null;
  successMessage = false;
  showPassword = false;
  message;
  errorMessage;
  policy;
  currentError = '';
  newPassError = false;
  minLen;
  maxLen;
  upCaseIND;
  lowCaseIND;
  numIND;
  spcCharIND;
  passExpiry;
  wrongPassAttempts;
  wrongPassHrs;
  passConfigID;
  prevPass;
  constructor(employeeServices,controllerFactory,jwtService,router,loginservice){
    this.employeeServices = employeeServices;
    this.controller = controllerFactory.createForCurrentScope();
    this.jwtService = jwtService;
    this.router = router;
      this.loginservice = loginservice;

    this.policy;
    
  }
  async attached() {
    const policy = await this.employeeServices.PassswordPolicy();
      this.minLen = policy.MinimumPasswordLength;
      this.maxLen = policy.MaximumPasswordLength;
      this.upCaseIND = policy.UpperCaseIND;
      this.lowCaseIND = policy.LowerCaseIND;
      this.numIND = policy.NumberIND;
      this.splCharIND = policy.SpecialCharIND;
      this.passExpiry = policy.PasswordExpiryDays;
      this.wrongPassAttempts = policy.WrongPasswordAttempts;
      this.wrongPassHrs = policy.WrongPasswordHours;
      this.passConfigID = policy.PasswordConfigurationID;
      this.prevPass = policy.PreviousPasswords;
    

    ValidationRules.customRule(
      'passwordHistory',
      (value, currentPassword) => { 
        //value !== currentPassword
        (newValue, _, currentPassword) => newValue !== this.currentPassword || !newValue
      },
      `\${$displayName} must be different from the previous ${policy.PreviousPasswords} passwords.`,
    );


    ValidationRules.customRule(
      'passwordAttempts',
      (value, obj) => {
        return obj.WrongPasswordAttempts <= policy.WrongPasswordAttempts;
      },
      `\${$displayName} exceeded the maximum number of wrong password attempts (${policy.WrongPasswordAttempts}). Please try again in ${policy.WrongPasswordHours} hour(s).`,
    );

    ValidationRules.customRule(
      'passwordExpiry',
      (value, obj) => {
        const today = new Date();
        const passwordDate = new Date(value);
        passwordDate.setDate(passwordDate.getDate() + policy.PasswordExpiryDays);
        return passwordDate >= today;
      },
      `\${$displayName} has expired. please change your password.`,
    );

      ValidationRules.customRule(
        'uppercase',
        (value, upcase) =>{
          const hasUpperCase = /[A-Z]/.test(value)
        if(upcase.upCaseIND === true){
          return hasUpperCase;
        }else{
          return true;
        }
      },
      'New password must contain one Upper case.'
      );

      ValidationRules.customRule(
        'lowercase',
        (value, lowcase) =>{
          const hasLowerCase = /[a-z]/.test(value);
        if(lowcase.lowCaseIND === true){
          return hasLowerCase;
        }else{
          return true;
        }
      },
      'New password must contain lower uppercase.'
      );

      ValidationRules.customRule(
        'number',
        (value, numInd) =>{
          const hasNumber = /\d/.test(value)
        if(numInd.numIND === true){
          return hasNumber;
        }else{
          return true;
        }
      },
      'New password must contain one number.'
      );


      ValidationRules.customRule(
        'specialChar',
        (value, splInd) =>{
        const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(value)
        if(splInd.splCharIND === true){
          return hasSpecialChar;
        }else{
          return true;
        }
      },
      'New password must contain one special character.'
      );    
     
      ValidationRules
        .ensure('currentPassword').required().withMessage("Current password is required.")
        // .then().satisfiesRule('passwordAttempts',this.currentPassword)
        .ensure('newPassword').required().withMessage("New password is required.")
        .minLength(this.minLen).withMessage(`New password must be at least ${this.minLen} characters.`)
        .maxLength(this.maxLen).withMessage(`New password must be at least ${this.maxLen} characters.`)
        .then()
        .satisfiesRule('specialChar', this.splCharIND)
        .then()
        .satisfiesRule('number', this.numIND)
        .then()
        .satisfiesRule('uppercase', this.upCaseIND)
        .then()
        .satisfiesRule('lowercase',this.lowCaseIND)
        // .satisfiesRule('passwordHistory',this.currentPassword)
        .ensure('confirmPassword').required().then().withMessage("Confirm password is required.").satisfies((value, object) => value === object.newPassword).withMessage("Confirm password is not matching.")
        .on(this);
  }

      changePassword(){   
        this.errors = null;
        this.controller.validate()
        .then(result => {
          if (result.valid) {
            this.empid = this.jwtService.getEmployeeId();
            this.newPassError = false;
            const inputField = document.getElementById("newPassword");
            inputField.style.borderColor = "white";
              var newPassData = {
                "OldPassword": this.currentPassword,
                "ChangePassword": this.newPassword,
                "EmployeeId": this.empid,
              };
              this.employeeServices.VerifyCurrentPassword(this.currentPassword,this.empid).then(response => {
                if(response === false){
                  console.log(response);
                  ValidationRules
                  this.errorMessage = 'Please enter your current password correctly';
                  ValidationRules
                    .ensure('currentPassword').required()
                    .ensure('newPassword').required()
                    .minLength(this.minLen)
                    .maxLength(this.maxLen)
                    .then()
                    .satisfiesRule('specialChar', this.splCharIND)
                    .then()
                    .satisfiesRule('number', this.numIND)
                    .then()
                    .satisfiesRule('uppercase', this.upCaseIND)
                    .then()
                    .satisfiesRule('lowercase',this.lowCaseIND)
                    .ensure('confirmPassword').required()
                    .then()
                    .satisfies((value, object) => value === object.newPassword)
                    .withMessage("Confirm Password is not matching.")
                    .on(this);                 
                  this.currentPassword = '';
                  setTimeout(()=>{this.errorMessage=null},3000);
                }else{
                  this.employeeServices.ChangeEmployeePassword(newPassData);
                  this.currentPassword = '';
                  this.newPassword = '';
                  this.confirmPassword = '';
                  this.message = 'Password successfully changed';
                  setTimeout(()=>{this.message=null},3000);
                }
              })
              .catch(promise => {
                promise.then(err => this.errors = err.errors)
              });                        
          }
        })
      }

      back(){
        this.router.navigateToRoute('dashboard');
      }

  togglePassword() {
    this.newPasswordFieldType = (this.newPasswordFieldType === 'password') ? 'text' : 'password';
  }
  }
