import { inject, bindable, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { App } from "../../../app";
import { DialogService } from "aurelia-dialog";
import { JwtService } from "../../../shared/services/jwtservice";
import flatpickr from "flatpickr";
import { Viewtimeoffrequestcomponent } from "../../leavemanagement/viewtimeoffrequest/viewtimeoffrequestcomponent";
import $ from "jquery";
import * as XLSX from "xlsx";
import { config } from "../../../shared/services/config";
import { constants } from "../../../shared/services/constants";
import { LoginService } from "../../../shared/services/loginservice";
import { ConfirmationService } from "../../../shared/services/confirmationservice";

@inject(
  DialogService,
  Router,
  App,
  JwtService,
  LoginService,
  ConfirmationService
)
@autoinject()
export class PeriodicReviewComponent {
  @bindable selectedOption = "";
  isDropdownOpen = false;
  selectedStatus = "ACT";
  GetAllEmployeesNames = [];
  defaultToDate;
  defaultFromDate;
  pageSize = config.grid_PazeSize;
  showEmployeeDropdown = false;
  selectedRecordId = null;
  isLoading = false;
  FavoritesExist = false;
  sitemapId = constants.PeriodicReviewSiteMapid;
  GetEmployeePeriodicReviewList = [];

  statusOptions = [
    { label: "All", value: "All", checked: false },
    { label: "Active", value: "PEAM", checked: true },
    { label: "Submitted", value: "CMP", checked: false },
  ];
  constructor(
    dialogService,
    router,
    App,
    JwtService,
    loginservice,
    ConfirmationService,
    element
  ) {
    this.dialogService = dialogService;
    this.router = router;
    this.app = App;
    this.jwtService = JwtService;
    this.empid = this.jwtService.getEmployeeId();
    this.element = element;
    this.loginservice = loginservice;
    this.ConfirmationService = ConfirmationService;
  }
  filters = [
    {
      value: "",
      keys: [
        "EmployeeName",
        "EmployeeCode",
        "EmployeeClassificationName",
        "PositionLevelName",
        "ReportingManagerName",
        "RequestDateText",
        "DueDateText",
      ],
    },
  ];
  async attached() {
    // if(this.jwtService.getToken() === undefined){
    //     this.loginservice.purgeAuth();
    //     this.router.navigateToRoute('login')
    // }
    const fromDate = new Date();
    fromDate.setMonth(fromDate.getMonth() - 3);
    this.FDate = fromDate.toLocaleDateString().replace(/\//g, "-");
    this.From = this.FDate;
    const toDate = new Date();
    toDate.setMonth(toDate.getMonth() + 3);
    this.TDate = toDate.toLocaleDateString().replace(/\//g, "-");
    this.To = this.TDate;
    flatpickr(this.FromdatePicker, {
      closeOnSelect: true,
      dateFormat: "d-m-Y",
      minDate: null,
      maxDate: null,
      defaultDate: fromDate,
      enableTime: false,
      enableSeconds: false,
      time_24hr: false,
      minuteIncrement: 1,
      hourIncrement: 1,
      timeFormat: "h:i K",
      clickOpens: false,
      onClose: function (selectedDates, dateStr, instance) {
        this.FromDate = dateStr;
      },
    });
    this.FromcalendarIcon.addEventListener("click", () => {
      this.FromdatePicker._flatpickr.open();
    });
    flatpickr(this.TodatePicker, {
      closeOnSelect: true,
      dateFormat: "d-m-Y",
      minDate: null,
      maxDate: null,
      defaultDate: toDate,
      enableTime: false,
      enableSeconds: false,
      time_24hr: false,
      minuteIncrement: 1,
      hourIncrement: 1,
      timeFormat: "h:i K",
      clickOpens: false,
      onClose: function (selectedDates, dateStr, instance) {
        this.default = dateStr;
      },
    });
    this.TocalendarIcon.addEventListener("click", () => {
      this.TodatePicker._flatpickr.open();
    });
    const selectedRadio = document.querySelector(
      'input[name="employees"]:checked'
    );
    this.selectedValue = selectedRadio ? selectedRadio.value : "";
    const selectedStatusList = this.statusOptions
      .filter((status) => status.checked)
      .map((status) => status.value);
    const empReview = {
      Fromdate: this.From,
      Todate: this.To,
      EmployeeStatus: this.selectedValue,
      StatusList: selectedStatusList,
      LoggedInEmployeeId: this.empid,
      // Department: this.departmentId,
    };

    this.isLoading = true;

    this.ConfirmationService.getEmployeePeriodicReviewList(
      empReview.LoggedInEmployeeId,
      empReview.Fromdate,
      empReview.Todate
    ).then((data) => {
      this.GetEmployeePeriodicReviewList = data.EmployeeList;
      // this.defaultToDate = this.EmployeeTimeOffRequests.Todate;
      // this.defaultFromDate = this.EmployeeTimeOffRequests.Fromdate;
      this.isLoading = false;
    });
    this.ConfirmationService.DepartmentDropdown(this.empid, "ACT").then(
      (data) => {
        this.departments = data;
        console.log(this.departments);
      }
    );

    this.ConfirmationService.EmployeeClassDropdown().then((data) => {
      this.employeeclasses = data;
      //console.log(this.employeeclasses)
    });

    this.ConfirmationService.GetAllEmployeeDetails("ACT", this.empid).then(
      (GetAllEmployees) => {
        this.GetAllEmployees = GetAllEmployees;
      }
    );
    sessionStorage.removeItem("activeTab");
    await this.ConfirmationService.GetPersonFavoriteItems(
      this.empid,
      this.sitemapId
    ).then((data1) => {
      this.FavoritesExist = data1;
      console.log(data1);
      console.log(this.FavoritesExist);
    });

    this.addToRecentItems();
    console.log(this.FavoritesExist);
  }
  selectOption(option) {
    this.selectedOption = option;
    this.isDropdownOpen = false;
  }
  selectAllChanged(event) {
    const isChecked = this.statusOptions[0].checked;
    this.statusOptions.forEach((status, index) => {
      if (index !== 0) {
        status.checked = isChecked;
      }
    });
  }
  handleCheckboxChange(event, status) {
    if (status.value === "All") {
      this.statusOptions.forEach((statusOption, index) => {
        if (index !== 0) {
          statusOption.checked = false;
        }
      });
    } else {
      const allChecked = this.statusOptions
        .slice(1)
        .every((statusOption) => statusOption.checked);
      this.statusOptions[0].checked = allChecked;
    }
  }
  //   async displayEmployeess(event) {
  //     this.showEmployeeDropdown = true;
  //     const statusVal = event.target.value;
  //     this.leavemanagementService
  //       .DepartmentDropdown(this.empid, statusVal)
  //       .then((data) => {
  //         this.departments = data;
  //         console.log(this.departments);
  //       });
  //   }

  employeeOutsideClick = (event) => {
    const dropdownContainer = document.getElementById("dropdown-menu");
    const inputElement = document.getElementsByName("name")[0];
    if (
      !dropdownContainer.contains(event.target) &&
      event.target !== inputElement
    ) {
      this.showEmployeeDropdown = false;
      this.filteredEmployees = [];
      document.removeEventListener("click", this.employeeOutsideClick);
    }
  };
  toggleDropdown($event, employee) {
    //console.log(employee);
    const isDropdownOpen = employee.showDropdown;
    this.$displayData.forEach((item) => {
      item.showDropdown = false;
      item.timeoffshowDropdown = false;
    });
    if (!isDropdownOpen) {
      employee.showDropdown = true;
      employee.timeoffshowDropdown = false;
      document.addEventListener("click", this.closeDropdown);
    }
    $event.stopPropagation();
  }
  closeDropdown = () => {
    this.$displayData.forEach((item) => {
      item.showDropdown = false;
      item.timeoffshowDropdown = false;
    });
    document.removeEventListener("click", this.closeDropdown);
  };
  viewToggleDropdown($event, employee) {
    //console.log(employee);
    const timeoffisDropdownOpen = employee.timeoffshowDropdown;
    this.$displayData.forEach((item) => {
      item.timeoffshowDropdown = false;
      item.showDropdown = false;
    });
    if (!timeoffisDropdownOpen) {
      employee.timeoffshowDropdown = true;
      employee.showDropdown = false;
      document.addEventListener("click", this.viewcloseDropdown);
    }
    $event.stopPropagation();
  }
  viewcloseDropdown = () => {
    this.$displayData.forEach((item) => {
      item.timeoffshowDropdown = false;
      item.showDropdown = false;
    });
    document.removeEventListener("click", this.timeoffcloseDropdown);
  };
  closeEmployeetoggle() {
    this.filteredEmployees.forEach((item) => {
      item.showEmployeeDropdown = false;
    });
    document.removeEventListener("click", this.closeEmployeetoggle);
  }
  showDialog(leaveAppId, photopath) {
    const viewdata = {
      leaveId: leaveAppId,
      EmployeePic: photopath,
    };
    this.dialogService.open({
      viewModel: Viewtimeoffrequestcomponent,
      model: viewdata,
    });
  }
  cancel() {
    this.controller.cancel();
  }
  // ApproveShowDialog(leaveAppId,StatusCode,EmployeeId, actions,photopath) {
  //     const approveData = {
  //         selectedRecordId : leaveAppId,
  //         StatusCode : StatusCode,
  //         EmployeeId:EmployeeId,
  //         actions:actions,
  //         PhotoPath : photopath,
  //     };
  //     this.dialogService.open({viewModel: ApproveTimeOffComponent, model: approveData});
  // }
  searcPeriodicReviewDetails() {
    const filterOpenElements = document.querySelectorAll(".filter-open");
    filterOpenElements.forEach((element) =>
      element.classList.remove("filter-open")
    );
    // const selectedStatusLists = this.selectedStatuses.map(status => status.value);
    const selectedStatusList = this.statusOptions
      .filter((status) => status.checked)
      .map((status) => status.value);

    const finalToDate = this.ToDate ? this.ToDate : this.defaultToDate;
    const finalFromDate = this.FromDate ? this.FromDate : this.defaultFromDate;
    console.log(finalToDate);
    const selectedRadio = document.querySelector(
      'input[name="employees"]:checked'
    );
    this.selectedValue = selectedRadio ? selectedRadio.value : "";
    const postPeriodicReview = {
      Fromdate: finalFromDate,
      Todate: finalToDate,
      EmployeeStatus: this.selectedValue,
      StatusList: selectedStatusList.includes("All")
        ? ["All"]
        : selectedStatusList.filter((status) => status !== "All"),
      DepartmentID: this.department,
      // EmployeeClassID: this.employeeclass,
      EmployeeID: this.empid,
    };

    this.isLoading = true;
    this.ConfirmationService.postEmployeePeriodicReviewList(
      postPeriodicReview
    ).then((data) => {
      this.GetEmployeePeriodicReviewList = data.EmployeeList;
      this.isLoading = false;
    });
  }
  exportData() {
    const data = this.GetEmployeePeriodicReviewList;
    const filename = "periodic review list";
    this.exportToExcel(data, filename);
  }

  exportToExcel(data, filename) {
    const requiredColumns = [
      "Employee name",
      // "Employee code",
      // "Employee class",
      "Department",
      "Designation",
      "Reporting manager",
       "Period from",
      "Period to",
      "Status",
    ];
    const filteredData = data.map((periodicReview) => {
      return {
        "Employee name": periodicReview.EmployeeName,
        // "Employee code": periodicReview.EmployeeCode,
        // "Employee class": periodicReview.EmployeeClassificationName,
        Department: periodicReview.DepartmentName,
        "Designation": periodicReview.Designation,
        "Reporting manager": periodicReview.ReportingManagerName,
        "Period from": periodicReview.RequestDateText,
        "Period to": periodicReview.DueDateText,
        Status: periodicReview.StatusText,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: requiredColumns,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }

  filterClick() {
    event.preventDefault();
    const clickedDiv = event.target.parentNode;
    clickedDiv.classList.toggle("filter-open");
    // Remove 'filter-open' class from other divs
    const divs = document.querySelectorAll(".filter-open");
    divs.forEach((div) => {
      if (div !== clickedDiv) {
        div.classList.remove("filter-open");
      }
    });
    const handleOutsideClick = (event) => {
      if (!clickedDiv.contains(event.target)) {
        divs.forEach((div) => {
          div.classList.remove("filter-open");
        });
        document.removeEventListener("click", handleOutsideClick);
      }
    };

    if (clickedDiv.classList.contains("filter-open")) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }
  }

  addToFavourites() {
    this.ConfirmationService.AddToFavourites(this.empid, this.sitemapId).then(
      (data) => {
        window.location.reload();
      }
    );
  }

  addToRecentItems() {
    this.ConfirmationService.AddToRecentItems(this.empid, this.sitemapId).then(
      (data) => {
        // window.location.reload();
      }
    );
  }
  resetDepartmentDropdown() {
    this.department = ""; // Reset the selected department to empty value
  }
  resetEmployesDropdown() {
    this.employeeclass = ""; // Reset the selected department to empty value
  }
  resetStatusDropdown() {
    this.statusOptions.forEach((status, index) => {
      if (index === 1 || index === 2) {
        status.checked = true; // Select 'Pending approval' and 'Pending with me'
      } else {
        status.checked = false; // Deselect other statuses
      }
    });

    // Update the selectedStatusList if needed
    this.selectedStatusList = this.statusOptions.filter(
      (status) => status.checked
    );
    console.log("Hii");
    this.attached();
    //console.log(this.selectedStatusList);
  }
  removeFilter(Allstatus, status) {
    if (status.label === "All") {
      this.statusOptions.forEach((option) => {
        option.checked = false;
      });
    } else {
      status.checked = false;
    }
    // this.statusOptions.forEach(status => {
    //     status.checked = false;
    // });
  }
  get selectedStatuses() {
    return this.statusOptions.filter((status) => status.checked);
  }
  resetPage() {
    console.log("Hii");
    this.attached();
    this.selectedStatus = "ACT";
  }
}
