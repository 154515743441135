import { inject, bindable, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { App } from "../../../app";
import { DialogService } from "aurelia-dialog";
import { JwtService } from "../../../shared/services/jwtservice";
import flatpickr from "flatpickr";
import { Viewtimeoffrequestcomponent } from "../../leavemanagement/viewtimeoffrequest/viewtimeoffrequestcomponent";
import $ from "jquery";
import * as XLSX from "xlsx";
import { config } from "../../../shared/services/config";
import { constants } from "../../../shared/services/constants";
import { LoginService } from "../../../shared/services/loginservice";
import { ConfirmationService } from "../../../shared/services/confirmationservice";

@inject(
  DialogService,
  Router,
  App,
  JwtService,
  LoginService,
  ConfirmationService
)
@autoinject()
export class ConfirmationFeedbackFormComponent {

  isDropdownOpen = false;
  showEmployeeDropdown = false;
  
  constructor(
    dialogService,
    router,
    App,
    JwtService,
    loginservice,
    ConfirmationService,
    element
  ) {
    this.dialogService = dialogService;
    this.router = router;
    this.app = App;
    this.jwtService = JwtService;
    this.empid = this.jwtService.getEmployeeId();
    this.element = element;
    this.loginservice = loginservice;
    this.ConfirmationService = ConfirmationService;
  }
  
  activate(reviewData) {
        this.params = reviewData;
        this.ActionName = this.params.ActionName,
        this.Name = this.params.Name,
        this.DOJText = this.params.DOJText
        this.Designation=this.params.Designation,
        this.ReportingManagerName=this.params.ReportingManagerName,
        this.ExpectedStatusChangedDateText=this.params.ExpectedStatusChangedDateText
        this.DepartmentName=this.params.DepartmentName,
        this.EmployeeClassificationName=this.params.EmployeeClassificationName,
        this.EmployeeID=this.params.EmployeeID,
        this.EmployeeStatusId=this.params.EmployeeStatusId
        console.log(reviewData);
  
}

    attached()
    {
        this.ConfirmationService.getLeaveDetails(this.EmployeeID
          ).then((data) => {
            this.getLeaveDetails = data;
            console.log(data);
            
          });
   
          this.ConfirmationService.getPeriodicReviewView(this.EmployeeStatusId,this.EmployeeID
            ).then((data) => {
              this.getPeriodicViewDetails = data.MyPeriodicList;
              console.log(data);
              
            });

            this.ConfirmationService.getConfirmationFeedbackView(this.EmployeeStatusId,this.EmployeeID
                ).then((data) => {
                  this.getConfirmationFeedbackView = data.ConfirmationFeedbackList;
                  console.log(data);
                  
                });
    }

    toggleDropdown($event, employee) {
        //console.log(employee);
        const isDropdownOpen = employee.showDropdown;
        this.$displayData.forEach((item) => {
          item.showDropdown = false;
          item.timeoffshowDropdown = false;
        });
        if (!isDropdownOpen) {
          employee.showDropdown = true;
          employee.timeoffshowDropdown = false;
          document.addEventListener("click", this.closeDropdown);
        }
        $event.stopPropagation();
      }
      closeDropdown = () => {
        this.$displayData.forEach((item) => {
          item.showDropdown = false;
          item.timeoffshowDropdown = false;
        });
        document.removeEventListener("click", this.closeDropdown);
      };
      viewToggleDropdown($event, employee) {
        //console.log(employee);
        const timeoffisDropdownOpen = employee.timeoffshowDropdown;
        this.$displayData.forEach((item) => {
          item.timeoffshowDropdown = false;
          item.showDropdown = false;
        });
        if (!timeoffisDropdownOpen) {
          employee.timeoffshowDropdown = true;
          employee.showDropdown = false;
          document.addEventListener("click", this.viewcloseDropdown);
        }
        $event.stopPropagation();
      }
      viewcloseDropdown = () => {
        this.$displayData.forEach((item) => {
          item.timeoffshowDropdown = false;
          item.showDropdown = false;
        });
        document.removeEventListener("click", this.timeoffcloseDropdown);
      };
      closeEmployeetoggle() {
        this.filteredEmployees.forEach((item) => {
          item.showEmployeeDropdown = false;
        });
        document.removeEventListener("click", this.closeEmployeetoggle);
      }
    
      cancel(){
        this.router.navigateToRoute('confirmationreview');
      }
  
}
