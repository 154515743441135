import { inject, bindable, autoinject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { App } from "../../../app";
import { DialogService } from "aurelia-dialog";
import { JwtService } from "../../../shared/services/jwtservice";
import $ from "jquery";
import * as XLSX from "xlsx";
import { config } from "../../../shared/services/config";
import { constants } from "../../../shared/services/constants";
import { LoginService } from "../../../shared/services/loginservice";
import { ConfirmationService } from "../../../shared/services/confirmationservice";

@inject(
  DialogService,
  Router,
  App,
  JwtService,
  LoginService,
  ConfirmationService
)
@autoinject()
export class MyPeriodicReviewComponent {
  @bindable selectedOption = "";
  isDropdownOpen = false;
  selectedStatus = "ACT";
  GetAllEmployeesNames = [];
  defaultToDate;
  defaultFromDate;
  pageSize = config.grid_PazeSize;
  showEmployeeDropdown = false;
  selectedRecordId = null;
  isLoading = false;
  FavoritesExist = false;
  sitemapId = constants.MyPeriodicReviewSiteMapid;
  MyperiodicReviewList = [];

  constructor(
    dialogService,
    router,
    App,
    JwtService,
    loginservice,
    ConfirmationService,
    element
  ) {
    this.dialogService = dialogService;
    this.router = router;
    this.app = App;
    this.jwtService = JwtService;
    this.empid = this.jwtService.getEmployeeId();
    this.element = element;
    this.loginservice = loginservice;
    this.ConfirmationService = ConfirmationService;
  }
  filters = [
    {
      value: "",
      keys: ["ReviewerEmployeeName", "RequestDate", "DueDate", "CreatedDT"],
    },
  ];
  async attached() {
    this.isLoading = true;

    this.ConfirmationService.getMyPeriodicReviewList(this.empid).then(
      (data) => {
        this.MyperiodicReviewList = data;
        console.log(data);
      }
    );
    this.isLoading = false;

    await this.ConfirmationService.GetPersonFavoriteItems(
      this.empid,
      this.sitemapId
    ).then((data1) => {
      this.FavoritesExist = data1;
      console.log(data1);
      console.log(this.FavoritesExist);
    });

    this.addToRecentItems();
  }
  exportData() {
    const data = this.MyperiodicReviewList;
    const filename = "myperiodicreview";
    this.exportToExcel(data, filename);
  }

  exportToExcel(data, filename) {
    const requiredColumns = [
      "Reviewer name",
      "Reviewer period from",
      "Reviewer period to",
      "Reviewer date",
      "Status",
    ];
    const filteredData = data.map((employee) => {
      return {
        "Reviewer name": employee.ReviewerEmployeeName,
        "Reviewer period from": employee.RequestDate,
        "Reviewer period to": employee.DueDate,
        "Reviewer date": employee.CreatedDT,
        Status: employee.StatusCode,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(filteredData, {
      header: requiredColumns,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  }

  addToFavourites() {
    this.ConfirmationService.AddToFavourites(this.empid, this.sitemapId).then(
      (data) => {
        window.location.reload();
      }
    );
  }

  addToRecentItems() {
    this.ConfirmationService.AddToRecentItems(this.empid, this.sitemapId).then(
      (data) => {
        // window.location.reload();
      }
    );
  }
}
